<template>
    <section class="content">
        <header class="content__title">
            <h1>Devotionals</h1>
            <small>A list of devotionals</small>
            <div class="actions">
                
            </div>
        </header>
        <div class="card-">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <ul class="listings">
                                <li v-for="(item, i) in items" :key="i">
                                    <div class="image">
                                        <img :src="item.photo" alt=".">
                                    </div>
                                    <div class="body">
                                        <div>{{ item.name }}</div>
                                        <div>{{ item.year }}</div>
                                        <div>GHS {{ item.price }}</div>
                                        <div class="action">
                                            <button class="btn btn-danger btn-sm" @click="removeListing(item, i)">
                                                Delete
                                            </button>
                                            <button class="btn btn-warning btn-sm" @click="deactivate(item, i)" v-if="item.is_live">
                                                Deactivate
                                            </button>
                                            <button class="btn btn-success btn-sm" @click="activate(item, i)" v-else>
                                                Activate
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <form @submit.prevent="addListing">
                                <div class="form-group">
                                    <croppa 
                                        ref="myCroppa"
                                        accept="image/*"
                                        v-model="myCroppa" 
                                        :width="200" 
                                        :height="250"
                                        :quality="1"
                                        :prevent-white-space="true"
                                        :show-remove-button="false" />
                                </div>
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <select class="form-control" v-model="listing.name" @change="onTypeChange" required>
                                        <option v-for="type in itemsTypes" :key="type">{{ type }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="">Year</label>
                                    <datetime :flow="['year']" format="yyyy" input-class="form-control" v-model="year" required />
                                </div>
                                <div class="form-group">
                                    <label for="">Price</label>
                                    <input type="text" class="form-control" v-model="listing.price" required>
                                </div>
                                <button class="btn btn-success" :disabled="isProcessing">
                                    Save Devotional
                                    <Loader v-if="isProcessing" />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </section>
</template>

<script>
import { nanoid } from 'nanoid';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
export default {
    data(){
        return {
            listing: {
                name: "",
                slug: "",
                photo: "",
                price: "",
                year: "",
            },
            year: "",
            myCroppa: {},
            itemsTypes: [
                "Daily Guide",
                "Daily Power",
                "Daily Super Food",
                "Daa Nkwa Abodoo",
            ],
            isProcessing: false,
            items: [],
        }
    },
    created(){
        this.getListings();
    },
    methods: {
        async addListing(){
            const photoDataBase64 = this.$refs.myCroppa.generateDataUrl()

            if (photoDataBase64 == "") {
                alert("Please select a photo")
                return
            }

            const filename = 'devotionals/' + nanoid() + '.jpg'

            const metadata = { contentType: 'image/jpeg' }

            this.listing.price = Number(this.listing.price)

            const listYear = dayjs(this.year).format('YYYY')

            this.listing.year = Number(listYear)

            // upload
            const reference = firebase.storage().ref(filename)

            this.isProcessing = true;

            await reference.putString(photoDataBase64, 'data_url', metadata)

            this.listing.photo = await reference.getDownloadURL()

            const list_id = listYear + '-' + this.listing.slug 

            await firebase.firestore().collection("listings").doc(list_id).set(this.listing)

            this.isProcessing = false;

            this.listing = {
                name: "",
                slug: "",
                photo: "",
                price: "",
                year: "",
            }

            this.$refs.myCroppa.refresh();

            this.getListings();
            
        },
        onTypeChange(){
            this.listing.slug = this.listing.name.toLowerCase().replace(/\s/g, '');
        },
        async getListings(){
            const snapshot = await firebase
                .firestore()
                .collection("listings")
                .get()
            this.items = snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
        },
        removeListing(item, i){
            if (confirm("Are you sure you want to delete this listing?")) {
                firebase
                    .firestore()
                    .collection("listings")
                    .doc(item.id)
                    .delete()
                    .then(() => {
                        this.items.splice(i, 1)
                    })
            }
        },
        deactivate(item, i) {
            if (confirm("Are you sure you want to deactivate this listing?")) {
                firebase
                    .firestore()
                    .collection("listings")
                    .doc(item.id)
                    .update({
                        is_live: false
                    })
                    .then(() => {
                        this.items[i].is_live = false
                    })
            }
        },
        activate(item, i) {
            if (confirm("Are you sure you want to activate this listing?")) {
                firebase
                    .firestore()
                    .collection("listings")
                    .doc(item.id)
                    .update({
                        is_live: true
                    })
                    .then(() => {
                        this.items[i].is_live = true
                    })
            }
        }
    }
}
</script>
